import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";
import Select from "react-select";
import { debounce } from "lodash";

export default function FeesItem() {
    const { http, user } = AuthUser();
    const [tasks, setTasks] = useState([]);
    const [date, setDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [loginUser, setloginUser] = useState(user.department_id);
    const [devision, setDevision] = useState([]);
    const [subject, setSubjects] = useState([]);
    const [year, setYear] = useState([]);
    const [term, setTerm] = useState([]);
    const [fees, setFeesList] = useState([]);
    const [standard, setstandard] = useState([]);
    const [selclass, setselclass] = useState("");
    const [allStudent, setAllStudent] = useState([]);
    const [allagent, setAllagents] = useState([]);
    const [selectedStud, setSelectedStudent] = useState("");
    const [selectedClassStud, setselectedClassStud] = useState("");
    const [selectedWay, setselectedway] = useState("");
    const [selectedDevStud, setselectedDevStud] = useState("");
    const [selectedAgent, setselectedAgent] = useState("");
    const [selectedAgentType, setselectedAgentType] = useState("");
    const [selectedAgentCategory, setselectedAgentCategory] = useState("");
    const [selectedFeesStud, setselectedFeesStud] = useState("");
    const [amount, setAmount] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [buttonText, setButtonText] = useState(false);


    useEffect(() => {
        const valueToSet = selectedStud ? selectedStud.value : "";
        fetchTaskDetails(valueToSet, selectedClassStud, selectedDevStud, selectedFeesStud, selectedWay, selectedAgent, selectedAgentType, selectedAgentCategory);

        http.get(`/subject?login_user=${user.id}`).then((res) => {
            setSubjects(res.data.data);
        });

        http.get(`/student_all?company_id=${user.company_id}`).then((res) => {
            setAllStudent(res.data.data);
        });

        http.get(`/location?company_id=${user.company_id}`).then((res) => {
            setAllagents(res.data.data);
        });

        // Fetch divisions
        http.get(`/devision?login_user=${user.id}`).then((res) => {
            setDevision(res.data.data);
        });

        http.get(`/terms?login_user=${user.id}`).then((res) => {
            setTerm(res.data.data);
        });

        http.get(`/class?company_id=${user.company_id}`).then((res) => {
            setstandard(res.data.data);
        });

        //year
        http.get(`/year?login_user=${user.id}`).then((res) => {
            setYear(res.data.data);
        });

        http.get(`/tution_feeslist?company_id=${user.company_id}`).then((res) => {
            setFeesList(res.data.data);
        });

    }, [selectedStud,
        selectedClassStud,
        selectedDevStud,
        selectedFeesStud,
        selectedWay,
        selectedAgent,
        selectedAgentType,
        selectedAgentCategory
    ]);

    const fetchTaskDetails = (idStd, clasStd, devStd, feeId, way, agent, types, category) => {
        setIsLoading(true);
        const fetchTaskDetailsDebounced = debounce(fetchTaskDetails, 500);
        let endpoint = `/student_annual_fee?id=${idStd}&dev=${devStd}&class=${clasStd}&fees_id=${feeId}&way=${way}&agent=${agent}&types=${types}&category=${category}&company_id=${user.company_id}`;

        http
            .get(endpoint)
            .then((res) => {
                setIsLoading(false);
                if (Array.isArray(res.data.data)) {
                    setTasks(res.data.data);
                } else {
                    setIsLoading(false);
                    console.error("API response is not an array");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error fetching tasks:", error);
            });
    };

    const handleSelectChangeClass = (event) => {
        setSelectedStudent("");
        setselectedClassStud(event.target.value);
    };


    const handleSelectChangeWay = (event) => {
        setSelectedStudent("");
        setselectedway(event.target.value);
    };

    const handleSelectChangeDev = (event) => {
        setSelectedStudent("");
        setselectedDevStud(event.target.value);
    };


    const handleSelectChangeAgent = (event) => {
        setSelectedStudent("");
        setselectedAgent(event.target.value);
    };

    const handleSelectChangeAgentType = (event) => {
        setSelectedStudent("");
        setselectedAgentType(event.target.value);
    };

    const handleSelectChangeAgentCategory = (event) => {
        setSelectedStudent("");
        setselectedAgentCategory(event.target.value);
    };


    const handleSelectChange = (selectedOption) => {
        setselectedDevStud("");
        setselectedClassStud("");
        setSelectedStudent(selectedOption);
    };

    const statusUpdate = (e) => {
        setCurrentStatus(e.target.value);
    };

    const initialState = {
        name: "",
    };

    const deleteTask = async (taskId) => {
        await http
            .delete(`/departments_del/${taskId}`)
            .then((response) => {
                toast.success("Departments Deleted successfully !");
                fetchTaskDetails();
            })
            .catch((err) => {
                console.error("Error deleting task", err);
            });
    };

    const options = allStudent.map((user) => ({
        value: user.id,
        label: `${user.adm_no} - ${user.ext_2}${user.ext_3} - ${user.class} - ${user.section}  - ${user.mobile}`,
    }));

    const handleClearFields = () => {
        window.location.reload();
    };

    const handleSubmit = async () => {
        if (tasks.length === 0 || !selectedFeesStud) {
            toast.error("Please select a student and fees type before submitting.");
            return;
        }

        if (!amount || !dueDate) {
            toast.error("Please enter due date and amount");
            return;
        }



        try {
            setButtonText(true);
            const batchSize = 50; // Adjust the batch size according to your server's capacity
            const batches = [];

            // Create batches
            for (let i = 0; i < tasks.length; i += batchSize) {
                const batch = tasks.slice(i, i + batchSize);
                batches.push(batch);
            }

            const batchPromises = batches.map(batch => {
                const batchData = batch.map(task => ({
                    admn_no: task.adm_no,
                    ext_1: task.roll_no,
                    first_name: task.ext_2,
                    last_name: task.ext_3,
                    class: task.class,
                    dev: task.section,
                    fees_id: selectedFeesStud,
                    student_id: task.id,
                    amount: amount,
                    ext_5: dueDate,
                    ext_3: task.photo,
                    std_type: task.std_type,
                    company_id: user.company_id,
                }));
                return http.post('/annual_fees', { tasks: batchData });
            });

            // Wait for all batch promises to be resolved
            await Promise.all(batchPromises);

            toast.success("Fees data updated successfully");
            fetchTaskDetails(selectedStud, selectedClassStud, selectedDevStud, selectedFeesStud);
            setButtonText(false);
        } catch (error) {
            console.error("Error submitting tasks:", error);
            toast.error("Error submitting tasks. Please try again.");
            setButtonText(false);
        }
    };


    function handleSelectChangeFees(event) {
        setselectedFeesStud(event.target.value);
    }

    function handleChangeAmount(event) {
        setAmount(event.target.value)
    }

    function handleChangeDate(event) {
        setDueDate(event.target.value)
    }

    return (
        <>
            <Toaster />

            <div className="container mt-2">
                <div className="row">
                    <div className="col-md-6 form-group text-end">
                        <select className="form-control mb-2" onChange={handleSelectChangeFees}>
                            <option value=""> Fees Type</option>
                            <option value=""> All</option>
                            {fees.map((depart) => (
                                <option key={depart.id} value={depart.id}>
                                    {depart.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-5 form-group">
                        <Select
                            options={options}
                            onChange={handleSelectChange}
                            value={selectedStud} // Set the selected value
                        />
                    </div>
                    <div className="col-md-1 form-group">
                        <Link to="/fees_item" className="btn bt-sm btn-success" onClick={handleClearFields}>
                            Clear
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2 form-group text-end">
                        <select className="form-control mb-2" onChange={handleSelectChangeWay}>
                            <option value="">Way</option>
                            <option value="One-Way">One-Way</option>
                            <option value="Two-Way">Two-Way</option>
                        </select>
                    </div>

                    <div className="col-md-1 form-group text-end">
                        <select className="form-control mb-2 border-danger border" onChange={handleSelectChangeClass}>
                            <option>Class</option>
                            {standard.map((depart) => (
                                <option key={depart.id} value={depart.class_name} name="class">
                                    {depart.class_name}
                                </option>
                            ))}

                        </select>
                    </div>

                    <div className="col-md-1 form-group text-end">
                        <select className="form-control mb-2" onChange={handleSelectChangeDev} name="dev">
                            <option>Division</option>
                            {devision.map((depart) => (
                                <option key={depart.id} value={depart.name}>
                                    {depart.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-2 form-group text-end">
                        <select className="form-control mb-2" onChange={handleSelectChangeAgent} name="dev">
                            <option value="">Location</option>
                            {allagent.map((depart) => (
                                <option key={depart.id} value={depart.name}>
                                    {depart.name}
                                </option>
                            ))}

                        </select>
                    </div>

                    <div className="col-md-1 form-group text-end">
                        <select className="form-control mb-2" onChange={handleSelectChangeAgentType} name="types">
                            <option value="">Type</option>
                            <option value="New">New</option>
                            <option value="Old">Old</option>
                        </select>
                    </div>

                    <div className="col-md-1 form-group text-end">
                        <select className="form-control mb-2" onChange={handleSelectChangeAgentCategory} name="types">
                            <option value=" ">Category</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                            <option value="G">G</option>
                            <option value="H">H</option>
                            <option value="I">I</option>
                            <option value="J">J</option>
                            <option value="K">K</option>
                            <option value="L">L</option>
                            <option value="M">M</option>
                            <option value="N">N</option>
                            <option value="O">O</option>
                            <option value="P">P</option>
                            <option value="Q">Q</option>
                            <option value="R">R</option>
                            <option value="S">S</option>
                            <option value="T">T</option>
                            <option value="U">U</option>
                            <option value="V">V</option>
                            <option value="W">W</option>
                            <option value="X">X</option>
                            <option value="Y">Y</option>
                            <option value="Z">Z</option>
                        </select>
                    </div>

                    <div className="col-md-2 form-group ">
                        <input type="number" name="amount" className="form-control border border-success border-2" placeholder="Amount" onChange={handleChangeAmount} min="0"></input>
                    </div>

                    <div className="col-md-2 form-group">
                        <input type="date" name="due_date" className="form-control border border-success border-2" placeholder="Due Date" onChange={handleChangeDate}></input>
                    </div>
                </div>
                <hr />


                <div className="row">
                    {isLoading ? (
                        <TaskSkeleton />
                    ) : (
                        <table className="table table-striped center_tbl mt-2">
                            <thead className="table-primary">
                                <tr>
                                    <th className="text-danger">No</th>
                                    <th>Adm No</th>
                                    <th>Roll No</th>
                                    <th>Student Name</th>
                                    <th>Class</th>
                                    <th>Division</th>
                                    <th>Way</th>
                                    <th>Cat</th>
                                    <th>Loc</th>
                                    <th>Fee Type</th>
                                    <th>Due Date</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((task, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{task.adm_no}</td>
                                        <td>{task.roll_no}</td>
                                        <td>{task.ext_2} {task.ext_3}</td>
                                        <td>{task.class}</td>
                                        <td>{task.section}</td>
                                        <td>{task.way}</td>
                                        <td>{task.category}</td>
                                        <td>{task.loc}</td>
                                        <td>{task.std_type}</td>
                                        <td>{moment(task.ext_5).format("DD MMM,YYYY")}</td>
                                        <td>{task.amount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                    <button type="submit" className="btn button btn-success" onClick={handleSubmit} disabled={buttonText}>
                        {buttonText ? "Submitting..." : "Update Fees"}
                    </button>
                </div>


            </div >
        </>
    );
}
