import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

export default function FeesList() {
    const { http, user } = AuthUser();
    const [tasks, setTasks] = useState([]);
    const [TotalCount, setTotalCount] = useState();
    const [TotalCountMRP, setTotalCountMRP] = useState();
    const [date, setDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [departments, setdepartments] = useState([]);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [loginUser, setloginUser] = useState(user.department_id);
    const [selectedStatus, setsselectedStatus] = useState();
    const [selectedStatusFinal, setsselectedStatusFinal] = useState("OPEN");
    const [selectedtp, setsselectedtp] = useState();
    const [selectedPurpose, setsselectePuropse] = useState();
    const [selectedAssigner, setsselecteAssiner] = useState();
    const [showModal, setShowModal] = useState(false);
    const [yearList, setYear] = useState([]);
    const [editUser, setEditUser] = useState({});
    const [cat, setCat] = useState([]);
    const [masterdata, setmasterdata] = useState([]);
    const [masterdataIndi, setMasterdataIndi] = useState([]);
    const [tasksind, setTasksInd] = useState([]);
    const [editColumn, setEditColumn] = useState(null);
    const [users, setUsers] = useState([]);
    useEffect(() => {
        http.get(`/category`).then((res) => {
            setCat(res.data.data);
        });
        http.get(`/department_user_company?&login_user=${user.id}`).then((res) => {
            setUsers(res.data.users);
        });

        http.get(`/year?&login_user=${user.id}`).then((res) => {
            setYear(res.data.data);
        });

        fetchTaskDetails(
            selectedStatus,
            selectedtp,
            selectedPurpose,
            selectedAssigner,
            selectedStatusFinal
        );
    }, [
        selectedStatus,
        selectedtp,
        selectedPurpose,
        selectedAssigner,
        selectedStatusFinal,
    ]);
    console.log(yearList);
    const fetchTaskDetails = (
        selectedStatus = "",
        selectedtp = "",
        selectedPurpose = "",
        selectedAssigners = "",
        selFinal = ""
    ) => {
        setIsLoading(true);
        let endpoint = `/tution_feeslist?status=${selectedStatus}&company_id=${user.company_id}`;

        http
            .get(endpoint)
            .then((res) => {
                setIsLoading(false);
                if (Array.isArray(res.data.data)) {
                    setTasks(res.data.data);
                    setTotalCount(res.data.total_count);
                    setTotalCountMRP(res.data.total_count_mrp);
                } else {
                    setIsLoading(false);
                    console.error("API response is not an array");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error fetching tasks:", error);
            });
    };

    const statusUpdate = (e) => {
        setCurrentStatus(e.target.value);
    };

    const handleSave = async (taskId) => {
        if (currentStatus === null || taskId === null) {
            toast.error("Please select proper status");
            return true;
        }
        try {
            const response = await http.put(
                `task_status_update?task_id=${taskId}&status=${encodeURIComponent(
                    currentStatus
                )}`
            );
            toast.success("Task status updated successfully !");
            fetchTaskDetails();
        } catch (error) {
            console.error("Error fetching tasks:", error);
            toast.error("Please select proper item");
        }
    };

    const initialState = {
        revised_date: "",
        revised_remark: "",
    };

    const handleChangeModal = (e) => {
        setFormData1({
            ...formData1,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === "category") {
            fetchUsersForDepartment(e.target.value);
        }
        if (e.target.name === "master_id") {
            fetchMasterIndividual(e.target.value);
        }
    };

    const fetchUsersForDepartment = async (departmentId) => {
        try {
            const response = await http.get(`/master_cat?cat=${departmentId}`);
            setmasterdata(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const deleteTask = async (taskId) => {
        await http
            .delete(`/fees_del/${taskId}`)
            .then((response) => {
                toast.success("Fees Deleted successfully !");
                fetchTaskDetails();
            })
            .catch((err) => {
                console.error("Error deleting task", err);
            });
    };

    const handleChangeType = async (event) => {
        setsselectedStatus(event.target.value);
    };

    const handleChangeTypeFinal = async (event) => {
        setsselectedStatusFinal(event.target.value);
    };

    const handleChangeTp = async (event) => {
        setsselectedtp(event.target.value);
    };

    const handleChangePurpose = async (event) => {
        setsselectePuropse(event.target.value);
    };

    const handleChangePurposeCust = async (event) => {
        setsselecteAssiner(event.target.value);
    };

    const [formData1, setFormData1] = useState({
        name: "",
        ext_1: "",
        ext_2: "",
    });

    const openEditModal = async (taskAll, index) => {
        setEditColumn(index);
        setShowModal(true);
        setFormData1({
            name: taskAll.name || "",
            ext_1: taskAll.ext_1 || "",
            ext_2: taskAll.ext_2 || "",
        });
        setEditUser(taskAll);
    };

    const closeModal = () => {
        setShowModal(false);
        setEditColumn("");
    };
    const saveEditedCustemer = async (e) => {
        e.preventDefault();
        try {
            if (!editUser) {
                // Handle the case when editUser is null
                console.error("No user data to edit.");
                return;
            }

            const response = await http.put(`/fees_edit/${editUser.id}`, formData1);
            if (Array.isArray(response.data.data)) {
                toast.success("Stock data updated successfully!");
                setShowModal(false);
                fetchTaskDetails();
            } else {
                console.log("API response is not an array");
            }
        } catch (error) {
            console.error("Error updating user", error);
            toast.error("An error occurred while updating the customer.");
        }
        setEditColumn("");
    };

    const fetchMasterIndividual = async (msterId) => {
        try {
            const response = await http.get(`/master_cat_indi?cat=${msterId}`);
            setMasterdataIndi(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const DropdownOption = ({ user }) => {
        return (
            <option key={user.id} value={user.id}>
                {user.item_name}
            </option>
        );
    };

    const downloadFile = (filename) => {
        // Construct the URL to the file.
        const fileUrl = `http://3.6.125.26/api/storage/tasks/${filename}`;

        // Create a link element
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", ""); // the download attribute can be empty
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Remove the link from the DOM
    };

    return (
        <>
            <Toaster />
            {showModal && (
                <form onSubmit={saveEditedCustemer}>
                    <div className="modal" tabindex="-1" style={{ display: "block" }}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        Edit Fees List
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={closeModal}
                                    ></button>
                                </div>
                                <div className="modal-body">

                                    <div className="form-group mt-3">
                                        <label>Year</label>
                                        <select
                                            name="ext_2"
                                            className="form-control"
                                            value={formData1.ext_2}
                                            onChange={handleChangeModal}
                                        >
                                            {yearList.map((yearOption) => (
                                                <option key={yearOption.id} value={yearOption.name}>
                                                    {yearOption.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label>Name</label>
                                        <input type="text"
                                            name="name"
                                            className="form-control"
                                            value={formData1.name}
                                            onChange={handleChangeModal}
                                        />
                                    </div>

                                    <div className="form-group mt-3">
                                        <label>Order</label>
                                        <input type="text"
                                            name="ext_1"
                                            className="form-control"
                                            value={formData1.ext_1}
                                            onChange={handleChangeModal}
                                        />
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}

            <div className="container mt-1">
                <div className="row">
                    <div className="col-8">
                        <h3>Fees List</h3>
                    </div>

                    <div className="col-4 text-end mt-4">

                        <Link className="mb-3 add_link" to="/fees_add">
                            Add Fees
                        </Link>

                    </div>
                </div>

                <div className="table-responsive">
                    {isLoading ? (
                        <TaskSkeleton />
                    ) : (
                        <table className="table table-striped center_tbl">
                            <thead className="table-primary">
                                <tr>
                                    <th className="text-danger">No</th>
                                    <th>Name</th>
                                    <th>Year</th>
                                    <th>Order</th>
                                    <th>Action</th>

                                    {/* Add other fields as necessary */}
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((taskuser, index) => (
                                    <tr
                                        className={editColumn === index ? "highlight" : ""}
                                        key={taskuser.id}
                                    >
                                        <td>{index + 1}</td>
                                        <td>{taskuser.name}</td>
                                        <td>{taskuser.ext_2}</td>
                                        <td>{taskuser.ext_1}</td>
                                        <td width="8%">
                                            {user.department_id === 1 && (
                                                <button
                                                    className="btn btn-sm btn-link"
                                                    onClick={() => openEditModal(taskuser, index)}
                                                >
                                                    ✏️
                                                </button>
                                            )}

                                            {/* {user.department_id == 1 && (
                                                <button
                                                    className="btn btn-sm btn-link"
                                                    onClick={() => {
                                                        if (
                                                            window.confirm(
                                                                "Are you sure you want to delete this Task?"
                                                            )
                                                        ) {
                                                            deleteTask(taskuser.id);
                                                        }
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            )} */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    );
}
