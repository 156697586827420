import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import TaskSkeleton from "./TaskSkeleton";

export default function TutionAdd() {
    const navigate = useNavigate();
    const { http, user } = AuthUser();
    const [users, setUsers] = useState([]);
    const [masterdata, setmasterdata] = useState([]);
    const [masterdataIndi, setMasterdataIndi] = useState([]);
    const [cat, setCat] = useState([]);
    const [year, setYear] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        ext_1: 0,
        ext_2: '',
        user_company_id: user.company_id
    });

    const initialState = {
        name: '',
        ext_1: 0,
        ext_2: '',
        user_company_id: user.company_id
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

        if (e.target.name === 'category') {
            fetchUsersForDepartment(e.target.value);
        }

        if (e.target.name === 'master_id') {
            fetchMasterIndividual(e.target.value)
        }
    };

    const fetchUsersForDepartment = async (departmentId) => {
        try {
            const response = await http.get(`/master_cat?cat=${departmentId}`);
            setmasterdata(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }

    }

    const fetchMasterIndividual = async (msterId) => {
        try {
            const response = await http.get(`/master_cat_indi?cat=${msterId}`);
            setMasterdataIndi(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }

    }

    useEffect(() => {
        setIsLoading(true);
        let endpoint = `/tution_feeslist?company_id=${user.company_id}`;
        http
            .get(endpoint)
            .then((res) => {
                setIsLoading(false);
                if (Array.isArray(res.data.data)) {
                    setTasks(res.data.data);
                } else {
                    setIsLoading(false);
                    console.error("API response is not an array");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error fetching tasks:", error);
            });

        http.get(`/department_user_company?&login_user=${user.id}`).then((res) => {
            setUsers(res.data.users);
        })

        http.get(`/category`).then((res) => {
            setCat(res.data.data);
        })

        http.get(`/year`).then((res) => {
            setYear(res.data.data);
        });

    }, [])
    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData();

        for (const key in formData) {
            form.append(key, formData[key]);
        }

        try {
            http.post('/fees_add', form, {

            }).then((res) => {
                toast.success("Fees added successfully !");
                setFormData(initialState);
                navigate('/fees_list');
            })

        } catch (error) {
            console.error('Error adding company:', error.response.data);
        }
    };

    const DropdownOption = ({ user }) => {
        return (
            <option key={user.id} value={user.id}>
                {user.item_name}
            </option>
        );
    };

    return (
        <>

            <div className="container mt-5">
                <Toaster />
                <h3>Add Fees</h3>
                <form onSubmit={handleSubmit}>
                    <div className='form-group mt-3'>
                        <label>Name</label>
                        <input
                            name="name"
                            className='form-control'
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className='form-group mt-3'>
                        <label>Year</label>
                        <select
                            name="ext_2"  // Added name attribute for formData
                            className='form-control'
                            value={formData.ext_2}  // Assuming you want to control the selected value
                            onChange={handleChange}  // Assuming you want to handle changes
                            required
                        >
                            <option value="">Select Year</option>
                            {year.map((years) => (
                                <option key={years.id} value={years.name}>
                                    {years.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button className='btn button btn-success mt-3' type="submit">Add</button>
                </form>

                <div className="table-responsive mt-3">
                    {isLoading ? (
                        <TaskSkeleton />
                    ) : (
                        <table className="table table-striped center_tbl">
                            <thead className="table-primary">
                                <tr>
                                    <th className="text-danger">No</th>
                                    <th>Name</th>

                                    <th>Year</th>

                                    {/* Add other fields as necessary */}
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((taskuser, index) => (
                                    <tr
                                        className={"highlight"}
                                        key={taskuser.id}
                                    >
                                        <td>{index + 1}</td>
                                        <td>{taskuser.name}</td>

                                        <td>{taskuser.ext_2}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    )
}
