import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";
import { useParams } from "react-router-dom";
// Import your CSS file for pagination styles

export default function ReportAll() {
    const { http, user } = AuthUser();
    const [tasks, setTasks] = useState([]);
    const [date, setDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [remark, setRemark] = useState(null);
    const [departments, setdepartments] = useState([]);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [loginUser, setloginUser] = useState(user.department_id);
    const [editColumn, setEditColumn] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editUser, setEditUser] = useState({});
    const [tasksAll, setTasksAll] = useState([]); // Ensure it's initialized as an empty array
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const { id } = useParams();

    useEffect(() => {
        let isMounted = true; // Track if the component is mounted
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await http.get(`/invoice_all?company_id=${user.company_id}&page=${currentPage}`);
                if (isMounted) { // Only update state if mounted
                    setTasksAll(response.data.data || []); // Ensure tasksAll is set to an empty array if data is undefined
                    setTotalPages(response.data.totalPages); // Set total pages from response
                }
            } catch (error) {
                console.error("Error bulk print files:", error);
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };
        fetchData();

        return () => {
            isMounted = false; // Cleanup function to set isMounted to false
        };
    }, [currentPage]); // Fetch data when currentPage changes

    const handlePageChange = (page) => {
        setCurrentPage(page); // Update current page
    };

    const statusUpdate = (e) => {
        setCurrentStatus(e.target.value);
    };

    const Pagination = ({ currentPage, totalPages, onPageChange }) => {
        const pageNumbers = [];

        for (let i = 1; i <= totalPages; i++) {
            if (i <= 3 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageNumbers.push(i);
            } else if (pageNumbers[pageNumbers.length - 1] !== '...') {
                pageNumbers.push('...');
            }
        }

        // Add the last three pages if not already included
        for (let i = totalPages - 2; i <= totalPages; i++) {
            if (i > 3 && !pageNumbers.includes(i)) {
                pageNumbers.push(i);
            }
        }

        return (
            <div className="pagination">
                {pageNumbers.map((number, index) => (
                    <button
                        key={index}
                        onClick={() => number !== '...' && onPageChange(number)}
                        className={number === currentPage ? 'active' : ''}
                    >
                        {number}
                    </button>
                ))}
            </div>
        );
    };
    console.log(tasksAll);
    return (
        <>
            <Toaster />
            <div className="container mt-1">
                <div className="row">
                    <h1>Report List</h1>
                </div>

                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <div className="row">  {/* Wrap the map function with a row */}
                        {tasksAll.map((taskuser, index) => (
                            <div key={index} className="col-auto">  {/* Use col-auto for automatic column sizing */}
                                {taskuser.club_id ? (
                                    <Link to={`/print_all/${taskuser.club_id}?num=${taskuser.invoice_id}`} className="btn btn-sm btn-link action_link file_bulck">
                                        📙
                                        <span>{taskuser.invoice_id}</span>
                                        {(taskuser.paid_exisit === 0 && taskuser.club_exisit === 0) || taskuser.club_ext11 === 1 ? (  // Updated condition
                                            <span className="badge bg-warning ms-2">D</span> // Add Bootstrap badge
                                        ) : null}
                                    </Link>
                                ) : (
                                    <Link to={`/print/${taskuser.paid_id}?num=${taskuser.invoice_id}`} className="btn btn-sm btn-link action_link file_bulck">
                                        📙
                                        <span>{taskuser.invoice_id}</span>
                                        {(taskuser.paid_exisit === 0 && taskuser.club_exisit === 0) || taskuser.club_ext11 === 1 ? (  // Updated condition
                                            <span className="badge bg-warning ms-2">D</span> // Add Bootstrap badge
                                        ) : null}
                                    </Link>
                                )}
                            </div>
                        ))}
                    </div>
                )}

                {/* Pagination Controls */}
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />

            </div>

        </>
    );
}