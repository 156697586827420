import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AuthUser from './AuthUser';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export default function ClassAdd() {
    const [devision, setDevision] = useState([]);
    const [subject, setSubjects] = useState([]);
    const [year, setYear] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);

    const navigate = useNavigate();
    const { http, user } = AuthUser();

    const [formData, setFormData] = useState({
        class_name: '',
        ext_1: '',
        ext_3: '',
        user_company_id: user.company_id,
    });

    useEffect(() => {
        // Fetch subjects
        http.get(`/subject?login_user=${user.id}`).then((res) => {
            setSubjects(res.data.data);
        });

        // Fetch divisions
        http.get(`/devision?login_user=${user.id}`).then((res) => {
            setDevision(res.data.data);
        });

        //year
        http.get(`/year?login_user=${user.id}`).then((res) => {
            setYear(res.data.data);
        });
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            const updatedSubjects = checked
                ? [...selectedSubjects, value]
                : selectedSubjects.filter((subjectId) => subjectId !== value);

            setSelectedSubjects(updatedSubjects);

            setFormData({
                ...formData,
                [name]: updatedSubjects.join(','),
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const form = new FormData();
            for (const key in formData) {
                form.append(key, formData[key]);
            }

            http.post('/class_add', form).then((res) => {
                toast.success('Class added successfully!');
                setFormData({
                    class_name: '',
                    ext_1: '',
                    user_company_id: user.company_id,
                });
                setSelectedSubjects([]);
                navigate('/class');
            });
        } catch (error) {
            console.error('Error adding class:', error.response.data);
        }
    };

    return (
        <>
            <div className="container mt-5">
                <Toaster />
                <h3>Add Class</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group mt-3">
                        <label>Class Name</label>
                        <input
                            name="class_name"
                            className="form-control"
                            value={formData.class_name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group mt-3">
                        <label>Devison</label>
                        <select
                            name="ext_1"
                            className="form-control"
                            value={formData.ext_1}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Division</option>
                            {devision.map((depart) => (
                                <option key={depart.id} value={depart.name}>
                                    {depart.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* <div className="form-group mt-3">
                        <label>Subject</label>
                        {subject.map((depart) => (
                            <div key={depart.id} className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`subject-${depart.id}`}
                                    name="ext_2"
                                    value={depart.id}
                                    checked={selectedSubjects.includes(depart.id.toString())}
                                    onChange={handleChange}

                                />
                                <label className="form-check-label" htmlFor={`subject-${depart.id}`}>
                                    {depart.name}
                                </label>
                            </div>
                        ))}
                    </div> */}


                    <div className="form-group mt-3">
                        <label>Year</label>
                        <select
                            name="ext_3"
                            className="form-control"
                            value={formData.ext_3}
                            onChange={handleChange}
                            required>
                            <option value="">Select Year</option>
                            {year.map((depart) => (
                                <option key={depart.id} value={depart.name}>
                                    {depart.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button className="btn button btn-success mt-3" type="submit">
                        Add
                    </button>
                </form>
            </div>
        </>
    );
}
