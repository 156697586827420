import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";

export default function Departmentview() {
  const { http, user } = AuthUser();
  const [tasks, setTasks] = useState([]);
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState(null);
  const [departments, setdepartments] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [loginUser, setloginUser] = useState(user.department_id);
  const [editColumn, setEditColumn] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editUser, setEditUser] = useState({});
  useEffect(() => {
    fetchTaskDetails();
  }, []);

  const fetchTaskDetails = () => {
    setIsLoading(true);
    let endpoint = `/departments?login_user=${user.id}`;

    http
      .get(endpoint)
      .then((res) => {
        setIsLoading(false);
        if (Array.isArray(res.data.data)) {
          setTasks(res.data.data);
        } else {
          setIsLoading(false);
          console.error("API response is not an array");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching tasks:", error);
      });
  };

  const statusUpdate = (e) => {
    setCurrentStatus(e.target.value);
  };

  const saveEditedCustemer = async (e) => {
    e.preventDefault();
    try {
      if (!editUser) {
        // Handle the case when editUser is null
        console.error("No user data to edit.");
        return;
      }

      const response = await http.put(`/dept_edit/${editUser.id}`, formData1);
      if (Array.isArray(response.data.data)) {
        toast.success("Department updated successfully!");
        setShowModal(false);
        fetchTaskDetails();
      } else {
        console.log("API response is not an array");
      }
    } catch (error) {
      console.error("Error updating user", error);
      toast.error("An error occurred while updating the customer.");
    }
    setEditColumn("");
  };

  const handleSave = async (taskId) => {
    if (currentStatus === null || taskId === null) {
      toast.error("Please select proper status");
      return true;
    }
    try {
      const response = await http.put(
        `task_status_update?task_id=${taskId}&status=${encodeURIComponent(
          currentStatus
        )}`
      );
      toast.success("Task status updated successfully !");
      fetchTaskDetails();
    } catch (error) {
      console.error("Error fetching tasks:", error);
      toast.error("Please select proper item");
    }
  };

  const initialState = {
    name: "",
  };

  const handleChangeModal = (e) => {
    setFormData1({
      ...formData1,
      [e.target.name]: e.target.value,
    });
  };

  const deleteTask = async (taskId) => {
    console.log(taskId);
    await http
      .delete(`/departments_del/${taskId}`)
      .then((response) => {
        toast.success("Departments Deleted successfully !");
        fetchTaskDetails();
      })
      .catch((err) => {
        console.error("Error deleting task", err);
      });
  };

  const openEditModal = async (taskAll) => {
    setShowModal(true);
    setFormData1({
      name: taskAll.name || "",
    });
    setEditUser(taskAll);
  };

  const [formData1, setFormData1] = useState({
    name: "",
  });

  const closeModal = () => {
    setShowModal(false);
    setEditColumn("");
  };

  return (
    <>
      <Toaster />
      {showModal && (
        <form onSubmit={saveEditedCustemer}>
          <div className="modal" tabindex="-1" style={{ display: "block" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Department</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-group  mt-3">
                    <label> Name </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={formData1.name}
                      onChange={handleChangeModal}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}

      <div className="container mt-1">
        <div className="row">
          <h1>Department</h1>
          {/* <Link className="btn-link  text-end mb-3" to="/department_add">
            Add
          </Link> */}
        </div>

        <div className="table-responsive">
          {isLoading ? (
            <TaskSkeleton />
          ) : (
            <table className="table table-striped">
              <thead className="table-primary">
                <tr>
                  <th className="text-danger">No</th>
                  <th>Name</th>
                  <th>Action</th>

                  {/* Add other fields as necessary */}
                </tr>
              </thead>
              <tbody>
                {tasks.map((taskuser, index) => (
                  <tr key={taskuser.id}>
                    <td>{index + 1}</td>
                    <td>{taskuser.name}</td>
                    <td>
                      {/* {taskuser.id !== 61 && taskuser.id !== 1 && (
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this Department?"
                              )
                            ) {
                              deleteTask(taskuser.id);
                            }
                          }}
                        >
                          Delete
                        </button>
                      )} */}
                      {taskuser.department_id == 1 && (
                        <button
                          className="btn  btn-sm btn-link"
                          onClick={() => openEditModal(taskuser)}
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
