import React, { useRef } from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export default function StudentView() {
    const navigate = useNavigate();
    const { http, user } = AuthUser();
    const { id } = useParams();
    const [users, setUsers] = useState([]);
    const [masterdata, setmasterdata] = useState([]);
    const [masterdataIndi, setMasterdataIndi] = useState([]);
    const [cat, setCat] = useState([]);
    const [usersjob, setUsersJob] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        adm_no: '',
        assigned_by: user.id,
        adm_date: '',
        gvt_no: '',
        class: '',
        section: '',
        roll_no: '',
        sibling_study: '',
        group_name: '',
        dob: '',
        category: '',
        mobile: '',
        cast: '',
        religion: '',
        adhar: '',
        email: '',
        club: '',
        photo: "",
        father: "",
        mother: "",
        father_occ: "",
        mother_phone: "",
        mother_occu: "",
        guardian_name: "",
        guardian_occu: "",
        guardian_relation: "",
        guardian_phone: "",
        ext_1: "",
        ext_2: "",
        ext_3: "",
        ext_4: "",
        ext_5: "",
        ext_6: "",
        ext_7: "",
        ext_8: "",
        ext_9: "",
        ext_10: "",
    });

    const initialState = {
        adm_no: '',
        assigned_by: user.id,
        adm_date: '',
        gvt_no: '',
        class: '',
        section: '',
        roll_no: '',
        sibling_study: '',
        group_name: '',
        dob: '',
        category: '',
        mobile: '',
        cast: '',
        religion: '',
        adhar: '',
        email: '',
        club: '',
        photo: "",
        ext_1: "",
        ext_2: "",
        ext_3: "",
        ext_4: "",
        ext_5: "",
        ext_6: "",
        ext_7: "",
        ext_8: "",
        ext_9: "",
        ext_10: "",
    }

    const contentRef = useRef(null);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

        if (e.target.name === 'category') {
            fetchUsersForDepartment(e.target.value);
        }

        if (e.target.name === 'master_id') {
            fetchMasterIndividual(e.target.value)
        }
    };

    const fetchUsersForDepartment = async (departmentId) => {
        try {
            const response = await http.get(`/master_cat?cat=${departmentId}`);
            setmasterdata(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }

    }

    const fetchMasterIndividual = async (msterId) => {
        try {
            const response = await http.get(`/master_cat_indi?cat=${msterId}`);
            setMasterdataIndi(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.get(`/student_marks?&id=${id}&company_id=${user.company_id}`);
                // Simulate a 20-second delay using setTimeout
                setTimeout(() => {
                    setUsersJob(response.data.data);
                    setLoading(false); // Set loading to false after setting the state
                }, 200); // 20 seconds in milliseconds
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false); // Set loading to false in case of an error
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData();

        for (const key in formData) {
            form.append(key, formData[key]);
        }

        const taskFileInput = document.querySelector('input[type="file"]');
        if (taskFileInput.files[0])
            form.append("ext2", taskFileInput.files[0]);

        for (const key in formData) {
            form.append(key, formData[key]);
        }
        try {
            http.post('/student_add', form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },

            }).then((res) => {
                toast.success("Profile data added successfully !");
                setFormData(initialState);
                navigate('/student_list');
            })
        } catch (error) {
            console.error('Error adding student:', error.response.data);
        }
    };

    const DropdownOption = ({ user }) => {
        return (
            <option key={user.id} value={user.id}>
                {user.item_name}
            </option>
        );
    };
    if (loading) {
        return <p>Loading...</p>; // You can replace this with a loading spinner or any other loading indicator
    }

    const handlePrint = () => {
        const printContent = contentRef.current.innerHTML;
        const originalContent = document.body.innerHTML;
        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
    };

    console.log(usersjob);
    return (
        <>
            <div className="container mt-2 row">
                <Toaster />
                <div className="d-flex justify-content-end mb-2">
                    <Link to={`/print_view/${usersjob[0].id}`}
                        className="d-flex justify-content-end mb-2"
                    >Print Preview
                    </Link>
                </div>
                <h3>Student Details</h3>
                <hr />
                <div className="col-md-4 mb-3" >
                    {usersjob[0].photo ? (
                        <img
                            src={`http://3.111.220.78/api/storage/tasks/${usersjob[0].photo}`}
                            width="135"
                            height="145"
                            alt="Photo"
                        />
                    ) : (
                        <img src="/dummy.jpg" alt="photo" width="135" height="145" />
                    )}
                </div>
                <div className='row  blue-input-container' ref={contentRef}>
                    <div className='col-md-4 border p-4 mx-1'>
                        <h4>Personal Details</h4>

                        <div className='form-group mt-3'>
                            <label>Admission Number</label>
                            <input
                                name="adm_no"
                                className='form-control'
                                value={usersjob[0].adm_no}

                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Admission Date</label>
                            <input
                                name="adm_date"
                                className='form-control'
                                value={moment(usersjob[0].adm_date).format('DD MMM,YYYY')}
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>First Name</label>
                            <input
                                name="ext_2"
                                className='form-control'
                                value={usersjob[0].ext_2}
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Last Name</label>
                            <input
                                name="ext_3"
                                className='form-control'
                                value={usersjob[0].ext_3}
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Gender</label>
                            <input
                                name="ext_9"
                                className='form-control'
                                value={usersjob[0].ext_9}
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>STS Number</label>
                            <input
                                name="gvt_no"
                                className='form-control'
                                value={usersjob[0].gvt_no}

                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Class</label>
                            <input
                                name="class"
                                className='form-control'
                                value={usersjob[0].class}
                            />
                        </div>


                        <div className='form-group mt-3'>
                            <label>Section</label>
                            <input
                                name="section"
                                className='form-control'
                                value={usersjob[0].section}
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Roll No</label>
                            <input
                                name="roll_no"
                                className='form-control'
                                value={usersjob[0].roll_no}
                                required
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Sibling Name & Class</label>
                            <input
                                name="ext_7"
                                className='form-control'
                                value={usersjob[0].ext_7}
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Identity Marks</label>
                            <input
                                name="ext_8"
                                className='form-control'
                                value={usersjob[0].ext_8}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Group Name</label>
                            <input
                                name="group_name"
                                className='form-control'
                                value={usersjob[0].group_name}

                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Date Of Birth</label>
                            <input
                                name="dob"
                                className='form-control'
                                value={moment(usersjob[0].dob).format('DD MMM,YYYY')}
                                type='text'
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Category</label>
                            <input
                                name="category"
                                className='form-control'
                                value={usersjob[0].category}
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Mobile Number</label>
                            <input
                                name="mobile"
                                className='form-control'
                                value={usersjob[0].mobile}
                                required
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Caste</label>
                            <input
                                name="cast"
                                className='form-control'
                                value={usersjob[0].cast}


                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Religion</label>
                            <input
                                name="religion"
                                className='form-control'
                                value={usersjob[0].religion}

                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Aadhaar No/UID</label>
                            <input
                                name="adhar"
                                className='form-control'
                                value={usersjob[0].adhar}

                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Email</label>
                            <input
                                name="email"
                                className='form-control'
                                value={usersjob[0].email}

                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Other Remark</label>
                            <input
                                name="club"
                                className='form-control'
                                value={usersjob[0].club}

                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Transport</label>
                            <input
                                name="club"
                                className='form-control'
                                value={usersjob[0].ext_12}

                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Way</label>
                            <input
                                name="club"
                                className='form-control'
                                value={usersjob[0].ext_13} />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Pickup Location</label>
                            <input
                                name="club"
                                className='form-control'
                                value={usersjob[0].ext_15} />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Previous Class</label>
                            <input
                                name="club"
                                className='form-control'
                                value={usersjob[0].ext_14} />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Agent</label>
                            <input
                                name="ext_11"
                                className='form-control'
                                value={usersjob[0].ext_11}
                            />
                        </div>

                        <div className="form-group mt-3">
                            <label>Photo</label>

                        </div>
                    </div>

                    <div className='col-md-4 border  p-4 mx-2'>
                        <h4>Parent / Guardian's Details</h4>

                        <div className='form-group mt-3'>
                            <label>Father's Name</label>
                            <input
                                name="father"
                                className='form-control'
                                value={usersjob[0].father}

                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Father Phone</label>
                            <input
                                name="ext_1"
                                className='form-control'
                                value={usersjob[0].ext_1}

                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Father's Occupation</label>
                            <input
                                name="father_occ"
                                className='form-control'
                                value={usersjob[0].father_occ}

                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Mother's Name</label>
                            <input
                                name="mother"
                                className='form-control'
                                value={usersjob[0].mother}

                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Mother's Phone</label>
                            <input
                                name="mother_phone"
                                className='form-control'
                                value={usersjob[0].mother_phone}

                            />
                        </div>


                        <div className='form-group mt-3'>
                            <label>Mother's Occupation</label>
                            <input
                                name="mother_occu"
                                className='form-control'
                                value={usersjob[0].mother_occu}
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Guardian's  Name</label>
                            <input
                                name="guardian_name"
                                className='form-control'
                                value={usersjob[0].guardian_name}
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Guardian's Relation</label>
                            <input
                                name="guardian_relation"
                                className='form-control'
                                value={usersjob[0].guardian_relation}
                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Guardian's Occupation</label>
                            <input
                                name="guardian_occu"
                                className='form-control'
                                value={usersjob[0].guardian_occu}


                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Guardian's Phone</label>
                            <input
                                name="guardian_phone"
                                className='form-control'
                                value={usersjob[0].guardian_phone}


                            />
                        </div>

                        <div className='form-group mt-3'>
                            <label>Guardian's Address</label>
                            <textarea name="guardian_address"
                                className='form-control'
                                value={usersjob[0].guardian_address}

                            ></textarea>
                        </div>
                        <hr />

                        <div className='border border-success p-3'>

                            <div className='form-group mt-3'>
                                <label>Current Address</label>
                                <textarea name="ext_4"
                                    className='form-control'
                                    value={usersjob[0].ext_4}
                                ></textarea>
                            </div>

                            <div className='form-group mt-3'>
                                <label>Permanent Address</label>
                                <textarea name="ext_5"
                                    className='form-control'
                                    value={usersjob[0].ext_5}
                                ></textarea>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-3 border  p-2 px-2'>
                        <h4>Miscellaneous Details</h4>

                        <div className='form-group mt-3'>
                            <label>Previous School Details</label>
                            <textarea name="pre_school"
                                className='form-control'
                                value={usersjob[0].pre_school}

                            >

                            </textarea>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Head</label>
                            <input
                                name="tc_num"
                                className='form-control'
                                value={usersjob[0].tc_num}

                            />
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}
